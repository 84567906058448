<template>
  <div :class="getExpandStatus ? 'body-pd' : 'body-expand-pd'">
    <Navigation />
    <Header title="View Profile" />
    <div class="flex-row">
      <div class="col1">
        <button
          class="button "
          @click="active = 'password'"
          :class="{ purple: active === 'password' }"
        >
          <ion-icon name="lock-closed-outline"></ion-icon>Change Password
        </button>
        <button
          class="button "
          @click="active = 'general'"
          :class="{ purple: active === 'general' }"
        >
          <ion-icon name="person-outline"></ion-icon>General
        </button>
      </div>
      <div
        class="card flex-row password"
        :class="{ active: active === 'password' }"
      >
        <Form
          @submit="updatePassword"
          :validation-schema="schema"
          :initial-values="formValues"
        >
          <div class="flex-row">
            <div class="col2">
              <div class="inputGroup">
                <label for="oldPassword">Old Password:</label>
                <Field name="oldPassword" type="password" />
                <ErrorMessage name="oldPassword" class="error-feedback" />
              </div>
              <div class="inputGroup">
                <label for="password">New Password:</label>
                <Field name="password" type="password" />
                <ErrorMessage name="password" class="error-feedback" />
              </div>
            </div>
            <div class="col2">
              <div class="inputGroup">
                <label for="confirmPassword">Retype New Password:</label>
                <Field name="confirmPassword" type="password" />
                <ErrorMessage name="confirmPassword" class="error-feedback" />
              </div>
            </div>
          </div>
          <div class="flex-row col3">
            <button type="submit" class="purple-button" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Save Changes</span>
            </button>
            <button type="reset" class="grey-button">Cancel</button>
          </div>
          <div v-if="passwordMessage" class="flex-row pb">
            <div
              class="alert"
              :class="passwordSuccessful ? 'alert-success' : 'alert-danger'"
              role="alert"
            >
              {{ passwordMessage }}
            </div>
          </div>
        </Form>
      </div>
      <div
        class="card flex-row general"
        :class="{ active: active === 'general' }"
      >
        <Form
          @submit="updateUser"
          :validation-schema="schema"
          :initial-values="formValues"
        >
          <div class="flex-row">
            <div class="col2">
              <div class="inputGroup">
                <label for="email">E-mail:</label>
                <Field name="email" type="email" disabled />
              </div>
              <div class="inputGroup">
                <label for="firstName">First Name:</label>
                <Field name="firstName" type="text" disabled />
                <ErrorMessage name="firstName" class="error-feedback" />
              </div>
            </div>
            <div class="col2">
              <div class="inputGroup">
                <label for="lastName">Last Name:</label>
                <Field name="lastName" type="text" disabled />
                <ErrorMessage name="lastName" class="error-feedback" />
              </div>
              <div class="inputGroup">
                <label for="company">Company:</label>
                <Field name="company" type="text" disabled />
              </div>
            </div>
          </div>
          <!-- <div class="flex-row col3">
            <button type="submit" class="purple-button" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Save Changes</span>
            </button>
            <button type="reset" class="grey-button">Cancel</button>
          </div> -->
        </Form>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Navigation from "../components/Navigation.vue";
import Header from "../components/Header.vue";
import Footer from "@/components/Footer.vue";
import AuthService from "../services/auth.service";

export default {
  name: "Profile",
  components: {
    Navigation,
    Header,
    Form,
    Field,
    ErrorMessage,
    Footer,
  },
  data() {
    const schema = yup.object().shape({
      firstName: yup.string().required("First Name is required!"),
      lastName: yup.string().required("Last Name is required!"),
      oldPassword: yup
        .string()
        .required("Password is required!")
        .min(6, "Must be at least 6 characters!")
        .max(40, "Must be maximum 40 characters!"),
      password: yup
        .string()
        .required("Password is required!")
        .min(6, "Must be at least 6 characters!")
        .max(40, "Must be maximum 40 characters!"),
      confirmPassword: yup
        .string()
        .required("Password is required!")
        .min(6, "Must be at least 6 characters!")
        .max(40, "Must be maximum 40 characters!")
        .oneOf([yup.ref("password")], "Passwords must match"),
    });

    const formValues = {
      firstName: "firstName",
      lastName: "lastName",
      email: "email",
      company: "company",
    };

    return {
      passwordSuccessful: false,
      // generalSuccessful: false,
      loading: false,
      passwordMessage: "",
      // generalMessage: "",
      schema,
      ticketDetails: "",
      formValues,
      active: "password",
    };
  },
  methods: {
    // async updateUser(user) {
    //   try {
    //     this.generalSuccessful = true;
    //     this.loading = true;
    //     await AuthService.updateUser(user);
    //     this.generalMessage = "User updated Successfully";
    //     this.loading = false;
    //   } catch (error) {
    //     this.generalSuccessful = false;
    //     this.loading = false;
    //     this.generalMessage = "Something went wrong";
    //   }
    // },
    async updatePassword(password) {
      try {
        this.loading = true;
        await AuthService.updatePassword(password);
        this.passwordSuccessful = true;
        this.passwordMessage = "Password changed Successfully";
        this.loading = false;
      } catch (error) {
        this.passwordSuccessful = false;
        this.loading = false;
        this.passwordMessage = "Password is incorrect";
      }
    },
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("user"));

    this.formValues.firstName = user.firstName;
    this.formValues.lastName = user.lastName;
    this.formValues.email = user.email;
    this.formValues.company = user.accountName;
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getExpandStatus() {
      return this.$store.state.responsive.expand;
    },
  },
};
</script>

<style>
.general,
.password {
  padding: 2rem 0rem 2rem 2rem;
  display: none;
}

.active {
  display: flex;
}

.active {
  display: flex;
}

.error-feedback {
  color: #ea5455;
  font-size: 0.8rem;
  font-family: "poppins";
  font-weight: 500;
}

.col3 button {
  width: 120px;
  text-align: center;
  padding: 0.35rem 1.5rem;
}

.col3 button:first-child {
  margin-top: 0rem;
  margin-right: 1rem;
  width: 180px;
}

.col2 {
  margin-right: 2rem;
  width: 50%;
}

.inputGroup {
  margin-bottom: 1rem;
}

.alert {
  font-weight: 600;
  font-size: 1rem;
  padding: 0.71rem 1rem;
  margin-bottom: 0;
  margin-top: 0.71rem;
}

.alert-danger {
  background-color: #fbdddd;
  border-color: #f9cfcf;
  box-shadow: rgb(234 84 85 / 40%) 0px 6px 15px -7px;
  color: #ea5455;
}

form {
  margin-right: 1rem;
  width: 100%;
}

label {
  color: #5e5873;
  font-size: 0.857rem;
  margin-bottom: 0.2857rem;
  display: block;
}

input,
textarea {
  overflow: hidden;
  padding: 0.35rem 0.5rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  margin-right: 1rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  width: 100%;
}

button {
  text-align: left;
  width: 100%;
  color: #212529;
  background-color: transparent;
  border-color: transparent;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0.8rem;
}

.purple {
  background-color: #7367f0;
  color: white;
}

button:first-child {
  margin-top: 0.5rem;
}

.col1 {
  width: 230px;
  margin-right: 1rem;
}

.card {
  width: calc(100% - 230px);
}

ion-icon {
  margin-right: 4px;
  margin-bottom: -4px !important;
  font-size: 1.2rem;
}

@media only screen and (max-width: 768px) {
  .flex-row .col1 {
    width: calc(100% - 1rem);
    margin-right: 1rem;
    display: flex;
  }
  .flex-row .col2 {
    width: 100%;
    margin-right: 1rem;
  }
  .flex-row {
    flex-wrap: wrap;
  }
  .card {
    width: calc(100% - 1rem);
  }
  .flex-row .col1 button:first-child {
    margin-top: 0rem;
  }

  .general {
    padding: 0;
  }
  .card {
    margin-right: 0rem;
    margin-bottom: 0.5rem;
    padding: 1rem;
  }
  .card input {
    margin-right: 0rem;
  }
  form,
  .col2,
  .col2 {
    margin-right: 0rem !important;
  }
  .flex-row .col1 {
    margin-right: 0rem !important;
  }
}
</style>

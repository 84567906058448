<template>
  <router-link
    :to="{
      name: link,
    }"
    :class="[link === 'dashboard' ? 'dashboard' : '', 'nav__link']"
  >
    <ion-icon :name="icon" class="nav__icon"></ion-icon>
    <span class="nav__name">{{ menu }}</span>
  </router-link>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    menu: String,
    link: String,
    icon: String,
  },
};
</script>

<template>
  <div class="l-navbar " :class="getExpandStatus ? 'expander' : ''" id="navbar">
    <nav class="nav">
      <div>
        <div class="nav__brand">
          <ion-icon
            name="menu-outline"
            class="nav__toggle"
            id="nav-toggle"
            @click="toggle"
          ></ion-icon>
          <router-link to="/" class="nav__logo">
            Fortify 247 Portal
          </router-link>
        </div>
        <div class="nav__list">
          <MenuItem menu="View Tickets" link="dashboard" icon="home-outline" />
          <MenuItem menu="New Ticket" link="new-ticket" icon="ticket-outline" />
          <MenuItem
            menu="Completed Tickets"
            link="completed-tickets"
            icon="checkmark-circle-outline"
          />
          <MenuItem menu="My Profile" link="profile" icon="person-outline" />
        </div>
      </div>

      <a @click.prevent="logOut" class="nav__link logout">
        <ion-icon name="log-out-outline" class="nav__icon"></ion-icon>
        <span class="nav__name">Log Out</span>
      </a>
    </nav>
  </div>
</template>

<script>
import MenuItem from "./MenuItem";

export default {
  name: "Navigation",
  components: {
    MenuItem,
  },
  computed: {
    getExpandStatus() {
      return this.$store.state.responsive.expand;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      if (window.innerWidth < 850) {
        this.$store.dispatch("responsive/toggleExpand");
      } else {
        this.$store.dispatch("responsive/toggleClose");
      }
    },
    toggle() {
      if (this.getExpandStatus) {
        this.$store.dispatch("responsive/toggleExpand");
      } else {
        this.$store.dispatch("responsive/toggleClose");
      }
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
      this.$store.state.serviceDesk.tickets = "";
      this.$store.state.serviceDesk.hardwareAssets = "";
    },
  },
};
</script>

<style scoped>
.nav__brand a {
  background-color: transparent;
}

a {
  text-decoration: none !important;
}

.logout {
  margin-bottom: 0;
}

nav a :hover {
  color: white;
}

nav :hover {
  color: white;
}
</style>

<template>
  <div class="horizontal-container header">
    <div class="title-wrapper">
      <router-link :to="{ name: 'dashboard' }">
        <ion-icon name="home-outline"></ion-icon>
      </router-link>
      <ion-icon name="chevron-forward-outline"></ion-icon>
      <span>{{ title }}</span>
    </div>
    <div class="profile-wrapper">
      <div class="name-wrapper">
        <p class="name">{{ firstName }} {{ lastName }}</p>
        <p class="account">{{ accountName }}</p>
      </div>
      <div class="icon">
        <img src="..\assets\images\pp.png" alt="avatar" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  props: {
    title: String,
  },
  methods: {},
  data() {
    return {
      firstName: "",
      lastName: "",
      accountName: "",
    };
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("user"));

    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.accountName = user.accountName;
  },
};
</script>

<style scoped>
ion-icon {
  margin-right: 4px;
  margin-bottom: -4px !important;
  font-size: 1.2rem;
  color: #212529;
}

.header {
  border-radius: 5px !important;
}

.nav__link {
  background-color: white;
}

.nav__link {
  display: inline;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 0;
  padding: 0;
  color: #212529;
  border-radius: 0;
  margin-bottom: 0px;
  transition: 0.3s;
  cursor: pointer;
}

.icon img {
  margin-bottom: -15px;
  width: 30px;
  border-radius: 50%;
}

.icon {
  padding-left: 10px;
}

.title-wrapper {
  width: calc(100% - 150px);
}
.profile-wrapper {
  display: flex;
  text-align: right;
  width: 300px;
  justify-content: flex-end;
}

.account {
  font-size: 12px;
}

p {
  margin-top: 0;
  margin-bottom: 0rem;
}

.name-wrapper .name {
  font-weight: 600 !important;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: #6e6b7b;
  font-size: 14px;
}

.name-wrapper .account {
  color: #6e6b7b;
}

.title-wrapper {
  float: right;
}

@media only screen and (max-width: 540px) {
  .horizontal-container {
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    padding: 8px 10px;
  }
  .icon img {
    margin-bottom: -10px;
    width: 20px;
  }
  .icon {
    padding-left: 5px;
  }

  .name-wrapper .name {
    font-size: 12px;
  }

  .account {
    font-size: 10px;
  }

  .title-wrapper {
    width: calc(100% - 112px);
  }

  .profile-wrapper {
    width: 112px !important;
  }

  ion-icon {
    font-size: 1rem !important;
  }

  .title-wrapper span {
    font-size: 14px;
  }
}

@media only screen and (max-width: 668px) {
  .horizontal-container .title-wrapper a,
  .horizontal-container .title-wrapper ion-icon {
    display: none !important;
  }
}
</style>
